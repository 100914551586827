.footer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    background: #333333
    color: #fff
    padding: 50px 0
    &__chevron
        color: #000
    &__links
        display: flex
        gap: 2rem
        margin: 25px 0 25px -4px
        & i
            color: #000
            background: #DEE2E6
            padding: 5px 
            border-radius: 5px
    &__para
        font-size: 1.25rem