#about
    background: #000
    width: 100%
    padding-bottom: 50px
.about
    padding: 60px 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-wrap: wrap
    &__logo
        width: 55%
        margin: 70px 0 80px 0
    &__title, &__para
        width: 60%
        color: #fff
        text-align: center
        line-height: 27px
        font-size: 22px
        font-family: 'Noto Serif', sans-serif
        @media screen and (max-width: 800px)
            width: 75%
    &__para
        padding-bottom: 20px
        & a
            color: #CEBF67
            text-decoration: underline