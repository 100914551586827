#services
    width: 100%
    padding: 60px 0
.services
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-wrap: wrap
    &__title
        color: #CEBE66
        font-size: 3.125rem
        font-weight: bold
        letter-spacing: 4px
        text-align: center
        border-top: 2px solid #CEB21A
        text-transform: uppercase
        padding-top: 20px
    &__container
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 4.5rem
        width: 100%
        padding: 60px 0 
        &__card
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            flex-wrap: wrap
            border-radius: 10px
            width: 250px
            position: relative
            border: 1px solid #000
            box-shadow: #000 0px 20px 30px -10px
            &__img
                width: 80px
                margin: 20px 0
            &__title
                margin-bottom: 20px
                color: #5C4800
                font-weight: bold
                font-size: 25px
            &__button
                background: #fff
                color: #5C4800
                border: 1px solid #5C4800
                border-radius: 5px
                padding: 10px 10px
                text-transform: uppercase
                font-weight: bold
                font-size: 14px
                margin-bottom: 20px
                &:hover
                    color: #fff
                    box-shadow: inset 0 0 0 2em #5C4800
                    transition: 500ms
            &__menu
                width: 150px
                height: 300px
                background: #fff
                border: 1px solid grey
                position: absolute
                z-index: 2
                overflow-y: scroll
                bottom: -280px
                &.close
                    display: none
                &.open
                    display: flex
                    flex-direction: column
                &__list
                    &__link
                        display: flex
                        align-items: center
                        color: #5C4800
                        font-size: 18px
                        margin: 3px 0
                        &__img
                            width: 28px
                            margin: 0 8px
                            border-radius: 20px
    &__subtitle
        color: #5C4800
        font-weight: bold
        font-size: 60px
        margin-bottom: 0.5rem
    &__para
        font-size: 1.5rem
        text-align: center
        width: 60%
        line-height: 1.2
        @media screen and (max-width: 800px)
            width: 75%
        & a
            color: #5C4800
            text-decoration: underline
    &__button
        margin-top: 2.5rem
        color: #5C4800
        border: 1px solid #5C4800
        border-radius: 5px
        padding: 15px 20px
        text-transform: uppercase
        font-weight: bold
        font-size: 24px
        &:hover
            color: #fff
            box-shadow: inset 0 0 0 2em #5C4800
            transition: 500ms