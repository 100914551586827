#relayer
    width: 100%
    padding: 60px 0
    background: #000
.relayer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-wrap: wrap
    &__title
        color: #CEBE66
        font-size: 3.125rem
        font-weight: bold
        letter-spacing: 4px
        text-align: center
        border-top: 2px solid #CEB21A
        text-transform: uppercase
        padding-top: 20px
    &__container
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: 2.5rem
        margin: 60px 0
        width: 80%
        &__card
            display: flex
            flex-direction: column
            align-items: center
            border: 1px solid #CEBE66
            border-radius: 15px
            box-shadow: #CEB21A 10px 15px 25px -10px
            @media screen and (max-width: 350px)
                width: 100%
            &__title
                color: #CEBE66
                font-weight: bold
                font-size: 2.5rem
                margin: 15px
                text-align: center
            &__list
                margin-bottom: 10px
                &__wallet
                    margin: 0 10px
                    &__link
                        display: flex
                        justify-center: center
                        align-items: center
                        margin-bottom: 5px
                        &__img
                            width: 30px
                            border-radius: 100px
                            margin-right: 10px
                            @media screen and (max-width: 610px)
                                width: 20px
                                @media screen and (max-width: 500px)
                                    display: none
                        &__para
                            text-align: center
                            color: #fff
                            font-size: 1.25rem
                            width: 100%
                            line-height: 1.5
                            @media screen and (max-width: 610px)
                                font-size: 1rem
                                @media screen and (max-width: 500px)
                                    font-size: 0.90rem
                                    @media screen and (max-width: 350px)
                                    font-size: 0.75rem
                            &:hover
                                text-decoration: underline
    &__para
        text-align: center
        color: #CEBE66
        font-size: 1.25rem
        font-weight: 700
        width: 90%
        & a
            color: #fff
            text-decoration: underline