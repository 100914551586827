#mainnet
    width: 100%
    padding: 60px 0
.mainnet
    width: 90%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-wrap: wrap
    &__title
        color: #CEBE66
        font-size: 3.125rem
        font-weight: bold
        letter-spacing: 4px
        text-align: center
        border-top: 2px solid #CEB21A
        text-transform: uppercase
        padding-top: 20px
        margin-bottom: 40px
    &__container
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 2.5rem
        width: 90%
        &__card
            display: flex
            flex-direction: column
            align-items: center
            width: 20rem
            border-radius: 10px
            padding: 15px 0
            border: 1px solid #000
            box-shadow: #000 0px 20px 30px -10px
            &__ico
                width: 100px
                height: 100px
                object-fit: cover
                border-radius: 50%
            &__title
                color: #5C4800
                padding: 15px 0
                font-size: 25px
                text-transform: uppercase
                font-weight: bold
            &__infos
                width: 100%
                text-align: center
                padding-bottom: 15px
                &__line
                    display: flex
                    justify-content: center
                    gap: 5%
            &__links
                display: flex
                gap: 7%
                & a
                    color: #5C4800
                    border-radius: 5px
                    border: 1px solid #5C4800
                    font-weight: bold
                    text-transform: uppercase
                    padding: 10px
                    &:hover
                        color: #fff
                        box-shadow: inset 0 0 0 2em #5C4800
                        transition: 500ms
.token
    font-weight: bold
.LORE
    color: #4249FF
.KYVE
    color: #4daf9d
.QCK
    color: #9b9b9b
.AXL
    color: #b2b6bc
.STARS
    color: #e38cd4
.KUJI
    color: #60fbd0
.XKI
    color: #3756fc
.KAVA
    color: #ff564f
.TORI
    color: #7367f0
.PASG
    color: #5da5fb
.BAND
    color: #516FFA
.FET
    color: #2aae9c
.DSM
    color: #ed6c53
.GRAVITON
    color: #007bb5
.BCNA
    color: #00b786
.LUM
    color: #3e68f9
.HASH
    color: #628ee4
.IXO
    color: #06b7dd
.DEC
    color: #426cdb
.NGM
    color: #86ccba
.ATOLO
    color: #8281fb