#testnet
    background: #000
    width: 100%
    padding: 60px 0
.testnet
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-wrap: wrap
    &__title
        color: #CEBE66
        font-size: 3.125rem
        font-weight: bold
        letter-spacing: 4px
        text-align: center
        border-top: 2px solid #CEB21A
        text-transform: uppercase
        padding-top: 20px
        margin-bottom: 40px
    &__container
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 2.5rem
        width: 90%
        &__card
            width: 30rem
            border: 4px solid #fff
            border-radius: 5px
            margin-bottom: 20px
            &__img
                width: 100%
                height: 100%
                object-fit: cover