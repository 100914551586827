.header__navbar 
    right: -100%
body .menu-open .header__navbar
    right: 0%
.header
    position: fixed
    top: 0
    width: 100%
    height: 52px
    background: #000
    padding: 4px 0px
    border-bottom: 1px solid #CEBF67
    z-index: 10
    &__navbar
        padding: 0 16px
        display: flex
        height: 100%
        justify-content: space-between
        align-items: center
        overflow: hidden
        &__btn
            display: none
            @media screen and (max-width: 900px)
                display: initial
                color: #CEBF67
                background: #000
                border: none
                z-index: 3000
        &__logo
            opacity: 0
            height: 40px
            @media screen and (max-width: 360px)
                height: 30px
        &__list
            width: 100%
            display: flex
            justify-content: flex-end
            align-items: center
            @media screen and (max-width: 900px)
                position: absolute
                top: 61px
                right: -100%
                height: 200px
                width: 8rem
                flex-direction: column
                justify-content: space-around
                background: #000
                border: 2px solid #CEBF67
                z-index: 2000
            &__link
                color: #CEBF67
                background: #000
                text-transform: uppercase
                letter-spacing: 4px
                padding: 10px 5px 10px 8px
                margin: 0 5px
                &:hover
                    color: #000
                    background: #CEBF67
                    border-radius: 5px
                &.active
                    color: #000
                    background: #CEBF67 
                    border-radius: 5px


